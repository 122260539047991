@tailwind base;
@tailwind components;
@tailwind utilities;

@responsive{
    .majorProject{
        @apply w-40 text-white text-lg font-semibold text-center bg-cover border-2 border-gray-200 rounded-md overflow-hidden shadow-lg;
    }

    .projectCount{
        @apply text-center py-1 md:py-0;
    }

    .mobileSlider{
        height: 25vh; min-height: 30vw;
    }

    .defaultSlider{
        height: 35vh; min-height: 0;
    }

    .mobileNews{
        height: 65vh; min-height: 60vw
    }

    .defaultNews{
        height: 65vh; min-height: 0;
    }

    .max-h-18{
        max-height: 4.4rem;
    }

    .projectBackground1{
        background-image: url('images/proje1.png');
    }
    
    .projectBackground2{
        background-image: url('images/proje2.png');
    }
    
    .projectBackground3{
        background-image: url('images/proje3.png');
    }
    
    .projectBackground4{
        background-image: url('images/proje4.png');
    }
    
    .projectBackground5{
        background-image: url('images/proje5.png');
    }
    
    .projectBackground6{
        background-image: url('images/proje6.png');
    }

    .popupMenu{
        @apply pt-1 absolute z-30 hidden bg-gray-100 group-hover:block border-b-4 border-red-800;
    }

    .defaultMenu ul li{
        @apply text-black hover:text-red-800 cursor-pointer;
    }

    .max-w-8\/10{
        max-width: 80%;
    }

    .w-8\/10{
        width: 80%;
    }
}

.slider {
    transition: transform 2s ease-in-out;
}

.slider2 {
    transition: transform 0.5s ease-in-out;
}

.sliderNavigationItem{
    @apply text-lg font-semibold w-6 text-center rounded cursor-pointer;
}

.sliderNavigationCurrentItem{
    @apply bg-gray-300 font-bold;
}

.flex-breaker{
    flex-basis: 100%;
    height: 0;
}

.svg-turkiye-haritasi {
    max-width: 1140px;
    margin: 0 auto;
    text-align: center;
}
.svg-turkiye-haritasi svg {
    width: 100%;
    height: auto;
}

.il-isimleri {
    position: absolute;
    z-index: 2;
}

.il-isimleri div {
    display: inline-block;
    background: #E30A17;
    color: #fff;
    padding: 8px 16px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

#svg-turkiye-haritasi path {
    cursor: pointer;
    fill: #555;
}

#svg-turkiye-haritasi path:hover {
    fill: #222;
}

#guney-kibris {
    pointer-events: none;
}

.videoEmbed {
    @apply overflow-hidden relative w-full;
}

.videoEmbed::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.videoEmbed iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.videoInline {
    @apply overflow-hidden relative mb-4 w-full md:w-8/10;
}

.videoInline::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.videoInline iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.unreset{
    a {
        color: #0000EE;
        text-decoration: underline;
    }

    hr {
        border: 1px inset;
        box-sizing: border-box;
        margin: 0.5em auto;
    }

    h1 {
        font-size: 2em;
        font-weight: bold;
        margin-bottom: 0.25em;
    }

    h2 {
        font-size: 1.6em;
        font-weight: bold;
        margin-bottom: 0.25em;
    }

    h3 {
        font-size: 1.30em;
        font-weight: bold;
        margin-bottom: 0.25em;
    }

    h4 {
        font-size: 1.15em;
        font-weight: bold;
        margin-bottom: 0.25em;
    }

    h5 {
        font-size: 1.00em;
        font-weight: bold;
        margin-bottom: 0.25em;
    }

    h6 {
        font-size: 0.85em;
        font-weight: bold;
        margin-bottom: 0.25em;
    }

    p {
        margin-bottom: 1.15em;
    }

    ul {
        list-style-type: disc;
        margin: 1em 0;
        padding: 0 0 0 40px;
    }

    ol {
        list-style-type: decimal;
        margin: 1em 0;
        padding: 0 0 0 40px;
    }

    ul, ol {

        ul {
            list-style-type: circle;
        }

        ul, ol {

            ul {
                list-style-type: square;
            }
        }
    }

    b, strong {
        font-weight: bold;
    }

    i, cite, em, var, address, dfn {
        font-style: italic;
        font-weight: inherit;
    }

    tt, code, kbd, samp {
        font-family: monospace;
        font-weight: inherit;
    }
}